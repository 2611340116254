<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>
      <el-upload
          class="filter-item"
          accept=".xlsx,.xls"
          action=""
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleImportAddress"
      >
        <el-button type="warning" icon="el-icon-upload"> 导入地址 </el-button>
      </el-upload>
      <el-button class="filter-item" type="success" @click="downloadExcel" >
        下载导入模板
      </el-button>
    </div>

    <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row>
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="地址" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.address_name }}
        </template>
      </el-table-column>
    <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag class="m-r-5" type="success" v-if="scope.row.status === 1"> 已启用 </el-tag>
          <el-tag class="m-r-5" type="warning" v-else-if="scope.row.status === 0"> 处理中 </el-tag>
          <el-tag class="m-r-5" type="danger" v-else-if="scope.row.status === 2"> 已停用 </el-tag>
          <el-tag class="m-r-5" type="danger" v-else-if="scope.row.status === 3"> 处理失败 </el-tag>
        </template>
      </el-table-column>
<!--      <el-table-column align="center" prop="created_at" label="创建时间" min-width="200">-->
<!--        <template slot-scope="scope">-->
<!--          <i class="el-icon-time"/>-->
<!--          <span>{{ scope.row.created_at }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="操作" align="center" width="250" class-name="small-padding fixed-width">
        <template  slot-scope="scope">
          <el-row>
            <el-button type="warning" size="mini" :loading="btnLoading" @click="handleUse(scope.row, '启用')" v-if="scope.row.status == 2">启用</el-button>
            <el-button type="danger" size="mini" :loading="btnLoading" @click="handleUse(scope.row, '停用')" v-if="scope.row.status == 1">停用</el-button>
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)" v-if="scope.row.status != 0">编辑</el-button>
            <el-button type="danger" size="mini" @click="handleDel(scope.row)" v-if="scope.row.status != 0">删除</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>


    <el-dialog
        :title="'导入数据预览（共' + this.yulanDataCount + '条）'"
        :visible.sync="importDataDialogVisible"
        center
        width="60%"
        :close-on-click-modal="false"
    >
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="yulanData" style="width: 1100px">
            <!--            <el-table-column label="序号" min-width="60" align="center">-->
            <!--              <template slot-scope="scope">-->
            <!--                {{ scope.row.index || "" }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column label="地址" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.address_name || "" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importDataDialogVisible = false">取消</el-button>
        <el-button @click="importSaveData" type="primary" :loading="btnLoading"
        >确认导入</el-button
        >
      </div>
    </el-dialog>


  </div>
</template>

<script>

import request from '@/utils/request'
import {list, stopOrStart, delAddress, importAddress} from "@/api/address";
import {excel2Json} from "@/utils/excel";
import {allocation} from "@/api/record";

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      yulanData: [],
      yulanDataCount:0,
      list: null,
      total: null,
      listLoading: true,
      btnLoading: false,
      importDataDialogVisible: false,
      listQuery: {
        page: 1,
        limit: 10
      },

      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      form: {
        id: '',
        username: '',
        password: '',
        roles: [],
        permissions: [],
      },
      rules: {
        create: {
          username: [{ required: true, message: '用户名不能为空', trigger: 'change' }],
          password: [{ required: true, message: '密码不能为空', trigger: 'change' }]
        },
        update: {
          username: [{ required: true, message: '用户名不能为空', trigger: 'change' }]
        }
      },

    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true
      list(this.listQuery).then((response) => {
        this.list = response.data.data
        this.total = response.data.total
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    resetForm() {
      this.form = {
        id: '',
        username: '',
        password: '',
        roles: [],
        permissions: [],
      },
        this.btnLoading = false
    },
    handleCreate() {
      this.$router.push("/address/detail");
      // this.resetForm()
      // this.dialogStatus = 'create'
      // this.dialogFormVisible = true
      // this.$nextTick(() => {
      //   this.$refs['form'].clearValidate()
      // })
    },
    handleUpdate(row) {
      this.$router.push("/address/detail?id=" + row.id)
    },
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          request({
            url: '/api/backend/admin/save',
            method: 'post',
            data: this.form
          }).then(response => {
            this.btnLoading = false
            this.dialogFormVisible = false
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success',
              duration: 2000
            })
            this.getList();
          }).catch((err) => {
            this.btnLoading = false
          })
        }
      })
    },
    handleUse(row,text) {
      this.btnLoading = true
      this.$confirm("此操作将"+ text + "地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        stopOrStart(row.id).then((response) => {
          this.getList();
          this.$message.success('操作成功');
        })
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.btnLoading = false
        });
      this.btnLoading = false
    },
    handleDel(row) {
      this.btnLoading = true
      this.$confirm("此操作将删除地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delAddress(row.id).then((response) => {
            this.getList();
            this.$message.success('操作成功');
          })
        }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
        this.btnLoading = false
      });
      this.btnLoading = false
    },
    handleImportAddress(file) {
      this.yulanData = [];
      file = file.raw;
      if (
          !(file.name.split(".")[1] == "xlsx" || file.name.split(".")[1] == "xls")
      ) {
        this.$message.error("上传文件格式不正确，请检查选择的文件");
        return;
      }
      excel2Json(file, 1).then((excelData) => {
        console.log(excelData);
        // 处理业务
        if (excelData.length <= 0) {
          this.$message.error("数据不能为空");
          return;
        }
        excelData.forEach((element, index) => {
          if (element["地址"]) {
            this.yulanData.push({
              address_name: element["地址"],
            });
          }
          console.log(element, "--------", index);
        });
        this.yulanDataCount = this.yulanData.length
        this.importDataDialogVisible = true;
        console.log(this.yulanData, "=============");
      });
    },

    importSaveData() {
      this.btnLoading = true;
      for (let index = 0; index < this.yulanData.length; index++) {
        const item = this.yulanData[index];
        for (var key in item) {
          if (!item[key]) {
            this.$message({
              type: "error",
              message: "请完善数据!!",
            });
            return;
          }
        }
      }
      importAddress(this.yulanData).then((response) => {
        if (response.code == 0) {
          this.btnLoading = false;
          this.importDataDialogVisible = false;
          this.getList();
          this.$notify({
            type: "success",
            message: "操作成功!",
            showClose:true,
            duration:15*1000
          });
        }
      }).catch((err) => {
        console.log(err);
        this.btnLoading = false;
      })
          .finally(() => {
            this.btnLoading = false;
          });
    },
    downloadExcel() {
      let a = document.createElement("a");
      a.href = "/导入地址模板.xlsx";
      a.download = "导入地址模板.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },





  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
